"use client"
import { Button, Result } from 'antd'
import LinkWrapper from '../components/linkWrapper/page'
import { useRouter } from 'next/navigation'

export default function NotFound() {
  const router = useRouter();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <div style={{ display: "flex", gap: 12, width: "100%", justifyContent: "center" }}>
          <Button type="primary" onClick={() => router.back()}>Go Back</Button>
          <LinkWrapper href="/">
            <Button type="primary">Go to Dashboard</Button>
          </LinkWrapper>
        </div>
      }
    />
  )
}